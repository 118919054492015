import {
  ContentfulResponse,
  IContentfulResponse
} from '@smu-chile/pkg-unimarc-hooks'
import { ConnectionCfBenefits } from 'shared/utils/connectionCfBenefits'

export const variableBreakdownCfBenefits = () => {
  const { dataBenefitsCF } = ConnectionCfBenefits({ include: 6 })
  const cfResponse = new ContentfulResponse(
    dataBenefitsCF as IContentfulResponse
  )
  const cfPopulate = cfResponse.populateEntries().getResponse()

  const [benefits] = cfPopulate?.items ?? []

  const banners = mapBanners(benefits?.imageBanner ?? [])

  return {
    banners
  }
}

export const mapBanners = (items: unknown[]) => {
  return items.map((item) => {
    return {
      id: item?.['sys']?.['id'],
      pictureSrc: item?.['image']?.['fields']?.['file']?.['url'] ?? null,
      title: item?.['label']
    }
  })
}
