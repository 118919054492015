import {
  MembershipCard,
  Column,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { Item, trigger } from '@smu-chile/pkg-unimarc-hooks'
import { HeaderClubBenefitsModal } from 'components/Header/components/HeaderClubBenefitsModal'
import { HeaderClubSavingModal } from 'components/Header/components/HeaderClubSavingModal'

export type MyAccountLevelProps = {
  actions?: Array<Item>
  color?: string
  fontColor?: string
  isLoading?: boolean
  level?: string
  pictureSrc?: string
  userName?: string
}

export const MyAccountLevel = ({
  actions,
  color,
  fontColor,
  isLoading,
  level,
  pictureSrc,
  userName
}: MyAccountLevelProps) => {
  const handleBenefitsClick = () => {
    trigger({ eventType: 'openClubBenefitsModal' })
  }

  const handleSavingButtonClick = () => {
    trigger({ eventType: 'openClubSavingModal' })
  }

  const mapActions = (action: Item) => {
    return {
      id: action.sys?.id,
      onClick: mapActionClick(action),
      pictureSrc: action.fields?.icon?.fields?.file?.url,
      text: action.fields?.label
    }
  }

  const mapActionClick = (action: Item) => {
    if (['Mis beneficios'].includes(action.fields?.label)) {
      return handleBenefitsClick
    }

    if (action.fields?.label?.includes('Ahorrado')) {
      return handleSavingButtonClick
    }

    return null
  }

  return (
    <MembershipCard
      actions={actions?.map(mapActions)}
      backgroundColor={color}
      isLoading={isLoading}
      pictureSrc={pictureSrc}
    >
      <Column
        alignItems='center'
        padding='0 2rem'
      >
        <Spacer.Horizontal customSize={11} />

        <Column alignItems='center'>
          <Text
            customColor={fontColor}
            fontSize='2xl'
            fontWeight='light'
            lineHeight='25px'
            textAlign='center'
          >
            Hola {userName}
          </Text>

          <Spacer.Horizontal customSize={3} />

          <Text
            customColor={fontColor}
            fontSize='xl'
            fontWeight='semibold'
            lineHeight='23px'
            textAlign='center'
          >
            {level}
          </Text>
        </Column>

        <Spacer.Horizontal customSize={22} />
      </Column>
      <HeaderClubBenefitsModal level={level} />
      <HeaderClubSavingModal />
    </MembershipCard>
  )
}
