import {
  Column,
  Container,
  ModalBottomSheet,
  Picture,
  Row,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { useRouter } from 'next/router'

interface ModalRepeatPurchaseProps {
  flowAddToCart?: boolean
  isMobile: boolean
  fruitsModalAddToCart?: string
  openModalRepeatPurchase: boolean
  repatPurchaseImage?: string
  setOpenModalRepeatPurchase: React.Dispatch<React.SetStateAction<boolean>>
}
export const ModalRepeatPurchase = ({
  flowAddToCart = false,
  isMobile,
  fruitsModalAddToCart,
  openModalRepeatPurchase,
  repatPurchaseImage,
  setOpenModalRepeatPurchase
}: ModalRepeatPurchaseProps) => {
  const router = useRouter()

  const flowWitdh = flowAddToCart ? '300px' : '265px'
  const customMaxWidth = isMobile ? flowWitdh : '340px'
  const customImageSize = isMobile ? '198px' : '164px'

  const textDescription = flowAddToCart
    ? 'Agregamos tus productos disponibles. ¡Estás a un paso de que sean tuyos!'
    : '  Tus productos ya fueron agregados. ¡Sólo te queda un paso para que sean tuyos!'

  const handleGoToCart = () => {
    setOpenModalRepeatPurchase(false)
    router.push(process.env.NEXT_PUBLIC_CARTURL)
  }

  const handleKeepShopping = () => {
    setOpenModalRepeatPurchase(false)
    router.push(process.env.NEXT_PUBLIC_HOMEURL)
  }

  return (
    <ModalBottomSheet
      hideSecondaryCTA={flowAddToCart ? true : false}
      isMobile={isMobile}
      isOpen={openModalRepeatPurchase}
      onClose={() => {
        return setOpenModalRepeatPurchase(false)
      }}
      primaryBtnLabel={flowAddToCart ? 'Ir a mi carro' : 'Ir al carro'}
      primaryCTA={handleGoToCart}
      secondaryBtnLabel='Seguir comprando'
      secondaryCTA={handleKeepShopping}
    >
      <>
        <Column
          alignItems='center'
          margin='36px 0 0 0'
          width='250px'
        >
          <Picture
            alt='banana'
            height='auto'
            src={flowAddToCart ? fruitsModalAddToCart : repatPurchaseImage}
            width={customImageSize}
          />
          <Container
            justifyContent='center'
            margin='27px 0'
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              textAlign='center'
            >
              Tu carro está listo
            </Text>
          </Container>
        </Column>
        <Row maxWidth={customMaxWidth}>
          <Text
            fontWeight='regular'
            isFlex
            textAlign='center'
          >
            {textDescription}
          </Text>
        </Row>
      </>
    </ModalBottomSheet>
  )
}
