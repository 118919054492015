import {
  ButtonStatus,
  Column,
  Container,
  Icon,
  Row,
  Spacer,
  Text
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  currencyFormat,
  getGlobalStyle,
  SmallScreen
} from '@smu-chile/pkg-unimarc-components/helpers'
import { IOrder } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/IOrder'
import { ResumeItem } from './ResumeItem'
import { useMobile } from '@smu-chile/pkg-unimarc-hooks'
import { statusPostPicking } from '../Products/components/ListProducts'
import { OrderActionDetail } from '..'
import { handleStatusTextOrderDetail } from 'shared/helpers'

interface TotalizerResumeProps {
  order?: IOrder
  text?: string
  loadingButton: ButtonStatus
  setLoadingButton: React.Dispatch<React.SetStateAction<ButtonStatus>>
}

interface TotalizerItemArr {
  id?: string
  name: string
  value: number | string
}

export interface TotalizerOrderFormItem {
  id: string
  name: string
  value: number
}

export const totalizerId = (
  totalizers: TotalizerOrderFormItem[],
  id: string
): number => {
  return (
    totalizers?.find((element) => {
      return element.id === id
    })?.value || 0
  )
}

export const totalizerIdName = (
  totalizers: TotalizerOrderFormItem[],
  id: string
): string => {
  return totalizers?.find((element) => {
    return element.id === id
  })?.name
}

export const TotalizerResume = (
  props: TotalizerResumeProps
): React.ReactElement => {
  const { isMobile } = useMobile()
  const { totalizers, total } = props.order
  const { text } = handleStatusTextOrderDetail(props.order)
  const arrayTotalizer: TotalizerItemArr[] = []
  const totalValue = { id: 'Total', name: 'Total', value: total / 100 }
  const showTextMesaggePostPicking =
    statusPostPicking.includes(props.text) &&
    totalizerId(totalizers, 'Change') != 0
      ? true
      : false
  const customWidtMessagePicking = isMobile ? '5' : '1'
  const alignItemsIconText = isMobile ? 'start' : 'center'
  const fontText = isMobile ? 'md' : 'xl'
  const fontTextModificacion = isMobile ? 'sm' : 'lg'
  const detailPadding = isMobile ? '16px' : '25px 32px'

  if (
    totalizers &&
    !totalizers.some((totalizer) => {
      return totalizer.id === 'Total'
    })
  ) {
    totalizers.push(totalValue)
  }

  const cartLimitArray = JSON.parse(
    props?.order?.customData?.customApps?.filter((value) => {
      return value.id == 'productcartlimit'
    })?.[0]?.fields?.limitData ?? '[]'
  )

  return (
    <Container isWrap>
      <Container
        alignItems='center'
        backgroundColor={getGlobalStyle('--color-neutral-white')}
        border={`${getGlobalStyle('--border-width-3xs')} solid ${getGlobalStyle(
          '--color-neutral-gray-divider'
        )}`}
        borderRadius={getGlobalStyle('--border-radius-md')}
        customHeight='auto'
        isWrap
        justifyContent='center'
        padding={detailPadding}
        tagName='section'
      >
        <Row>
          <Column>
            <Row>
              <Text
                fontSize={fontText}
                fontWeight='medium'
              >
                Detalle
                <SmallScreen>
                  <>:</>
                </SmallScreen>
              </Text>
              <Spacer.Vertical size={32} />
              <Spacer.Vertical size={4} />
              <BigScreen>
                <Column padding='10px 0px 0px 0px'>
                  <Spacer.Horizontal size={2} />
                  <Spacer.Horizontal
                    backgroundColor={getGlobalStyle('--color-neutral-gray')}
                    size={1}
                  />
                </Column>
              </BigScreen>
            </Row>
            <Spacer.Horizontal size={4} />
            {totalizers.map((item: TotalizerItemArr, index) => {
              let shippingValue: number | string = item.value
              if (
                item.name === totalizerIdName(totalizers, 'Shipping') &&
                item.value === 0
              ) {
                item.value = 'Gratis'
                shippingValue = item.value
              }

              if (
                item.name === totalizerIdName(totalizers, 'Shipping') &&
                typeof item.value === 'number' &&
                item.value > 0
              ) {
                shippingValue = currencyFormat({
                  number: item.value,
                  bool: false
                })
              }

              if (item.value !== 0 && item?.id !== 'Discounts') {
                const boolIcon =
                  item.name === totalizerIdName(totalizers, 'Change')
                    ? true
                    : false
                return (
                  <ResumeItem
                    booleanIcon={boolIcon}
                    itemName={item.name}
                    itemValue={shippingValue}
                    key={`${item.name}_${item.value}`}
                    latest={index === arrayTotalizer.length - 1}
                    totalizers={totalizers}
                  />
                )
              }
              return null
            })}
            {showTextMesaggePostPicking && (
              <Container isWrap>
                <Spacer.Horizontal size={12} />
                <Row isWrap>
                  <Spacer.Horizontal
                    backgroundColor={getGlobalStyle('--color-primary-disabled')}
                    size={1}
                  />
                </Row>
                <Spacer.Horizontal size={12} />
                <Row alignItems={alignItemsIconText}>
                  <Column customWidth={customWidtMessagePicking}>
                    <SmallScreen>
                      <Spacer.Horizontal size={4} />
                    </SmallScreen>
                    <Icon
                      color={getGlobalStyle('--color-base-black')}
                      name='Warning'
                      sizes='xs'
                    />
                  </Column>
                  <Spacer.Vertical size={8} />
                  <Column>
                    <Text fontSize={fontTextModificacion}>
                      Total por modificaciones de productos sustitutos y/o sin
                      stock
                    </Text>
                  </Column>
                </Row>
              </Container>
            )}
          </Column>
        </Row>
      </Container>
      {/* button repeat purchase*/}
      <BigScreen>
        <Spacer.Horizontal size={12} />
      </BigScreen>
      <BigScreen>
        <Container
          alignItems='center'
          backgroundColor={getGlobalStyle('--color-neutral-white')}
          border={`0.3px solid ${getGlobalStyle(
            '--color-neutral-gray-divider'
          )}`}
          borderRadius={getGlobalStyle('--border-radius-md')}
          customHeight='auto'
          isWrap
          justifyContent='center'
          padding='17px 30px'
          tagName='section'
        >
          <Row>
            <Column>
              <OrderActionDetail
                alignItems='end'
                cartLimitArray={cartLimitArray}
                customButtonProps={{
                  isLoading: props.loadingButton === 'loading'
                }}
                items={props.order.items}
                justifyContent='end'
                loadingButton={props.loadingButton}
                maxWidthColumn='100'
                setLoadingButton={props.setLoadingButton}
                tagInfo={{
                  orderState: text.toLowerCase(),
                  sequence: props?.order?.sequence,
                  totalizers: totalizers
                }}
                widthButton='228px'
              />
            </Column>
          </Row>
        </Container>
      </BigScreen>
      {/* button repeat purchase*/}
    </Container>
  )
}
