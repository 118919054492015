import { useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import {
  Container,
  SomethingIsWrongModal,
  Spacer,
  Toast
} from '@smu-chile/pkg-unimarc-components'
import {
  BigScreen,
  SmallScreen,
  getGlobalStyle
} from '@smu-chile/pkg-unimarc-components/helpers'
import { DeliverySelectorTypeComponent } from 'components/DeliverySelectorType'
import { StoreLocatorComponent } from '../StoreLocator'
import {
  cleanUrlParamsLogin,
  ContentfulResponse,
  eventClick,
  getParamsInURL,
  IAlertMessage,
  IContentfulResponse,
  IPostValidateSC,
  IResponse,
  isValidArrayWithData,
  postValidateSc,
  sleep,
  updateCurierName,
  useAbandonedCart,
  useContentful,
  useEvents,
  useInitialDatalayer,
  useMobile,
  useNewCartUnimarc,
  useOrderForm,
  useQueryClient,
  useSession,
  useSessionState,
  useTrigerEvent,
  useUpdateQuantityQuickly
} from '@smu-chile/pkg-unimarc-hooks'
import Cookies from 'js-cookie'
import { Header } from 'components/Header'
import { ValidateSlaWrapper } from 'components/ValidateSlaWrapper'
import { SOMETHING_IS_WRONG_MODAL_IMAGE } from 'shared/constants'
import { NoStockModal } from 'components/NoStockModal'
import { DataCart } from '@smu-chile/pkg-unimarc-hooks/shared/interfaces/INewCartUnimarc'
import { MembershipWrapper } from 'components/MembershipWrapper'
import { AuthWrapper } from 'components/AuthWrapper'

let saveCourierNameOneLoad = false

interface LayoutProps {
  children: React.ReactNode
}

export const Layout = ({ children }: LayoutProps): React.ReactElement => {
  const paramsApp = getParamsInURL('source')
  const { trigger } = useTrigerEvent()
  const { isMobile } = useMobile()
  const { data, isLoading } = useOrderForm()
  const { data: newCart } = useNewCartUnimarc()

  const router = useRouter()
  const [orderFormExist, setOrderFormExist] = useState(false)
  const [validateSlaResponse, setValidateSlaResponse] =
    useState<IResponse<IPostValidateSC>>()
  const [openModalSla, setOpenModalSla] = useState(false)
  const session = useSession()
  const { handleMutate } = useUpdateQuantityQuickly()
  const queryClient = useQueryClient()
  const [openSmIsWrongModal, setOpenSmIsWrongModal] = useState(false)
  const [errorCodeResponse, setErrorCodeResponse] = useState('')

  const [failedData, setFailedData] = useState(null)

  const [isDownloadToastOpen, setIsDownloadToastOpen] = useSessionState(
    'isDownloadToastOpen',
    false
  )

  useEffect(() => {
    if (isValidArrayWithData(newCart?.removedProducts)) {
      setFailedData(newCart)
    }
  }, [newCart])

  useAbandonedCart()
  useInitialDatalayer({
    isLoggedIn: session?.isLoggedIn,
    sc: data?.data?.salesChannel
  })

  const downloadToastResponse = useContentful({
    options: {
      content_type: 'alertMessage',
      'fields.abled': true,
      'fields.idFormato': 1,
      'fields.platform': 'Web Unimarc eComm'
    }
  })

  const showHeader = !isMobile || router.pathname !== '/'

  useEvents({
    eventType: 'somethingIsWrong',
    callBack: ({ detail: { show, errorCode } }) => {
      setErrorCodeResponse(errorCode)
      setOpenSmIsWrongModal(!!show)
    }
  })

  useEvents({
    eventType: 'loadingOrderForm',
    callBack: async ({ detail: { loading } }) => {
      if (!loading) localStorage?.setItem('addToCart', '[]')
      await sleep(1000)
      const getMutateData = queryClient
        .getMutationCache()
        .findAll({ mutationKey: 'mutateOrderForm' })
        .slice(-1)[0]
      const getRepeatPurchesData = queryClient
        ?.getMutationCache()
        .findAll({ mutationKey: 'mutateRepeatPurchase' })
        .slice(-1)[0]

      const dataCartMutate = getMutateData?.state?.data as DataCart
      const dataCartRepeatPurchase = getRepeatPurchesData?.state
        ?.data as DataCart
      const dataCartToValidate = dataCartRepeatPurchase || dataCartMutate

      if (
        isValidArrayWithData(dataCartToValidate?.removedProducts) &&
        !failedData
      ) {
        setFailedData(dataCartToValidate)
        trigger({
          eventType: 'hasRemovedProducts',
          data: { hasRemovedProducts: true }
        })
      }

      if (
        !loading &&
        !isValidArrayWithData(dataCartToValidate?.removedProducts)
      ) {
        handleCloseNoStockModal(dataCartToValidate, setFailedData)
      }
    }
  })

  const somethingIsWrongModal = useContentful({
    id_contentful: 'contentful-builtin-asset-content-type-modal-sm-is-wrong',
    options: {
      'sys.id': SOMETHING_IS_WRONG_MODAL_IMAGE[0]
    },
    type: 'assets'
  })

  const somethingIsWrongModalImage =
    somethingIsWrongModal.data?.['items']?.[0]?.fields?.file?.url || ''

  const changeBodyColor = () => {
    document.body.style.background = getGlobalStyle('--color-background-white')
  }

  const handleCloseNoStockModal = (
    dataCart: DataCart,
    setFailedData: (data: DataCart) => void
  ) => {
    setFailedData(null)
    const booleanOpenModal =
      dataCart?.products?.[0]?.items === null ||
      dataCart?.products?.[0]?.items?.length === 0
        ? false
        : true
    trigger({
      eventType: 'closeNoStockModal',
      data: { closeNoStockModal: booleanOpenModal }
    })
  }

  useEffect(() => {
    changeBodyColor()
    cleanUrlParamsLogin({
      router,
      ignoreItems: [
        'q',
        'page',
        'og',
        'seller',
        'source',
        'login',
        'changeMembershipCard'
      ]
    })
  }, [])

  // The role of this function is to search the "addTocart" cookie and
  // send his data to the backend for add of the orderForm
  // and the same way validate if the use mobile and force reload when a change to a page with arrow back
  useEffect(() => {
    const checkAddToCartPending = () => {
      if (!session?.isLoggedIn) return
      const recoverAddToCartPending = JSON.parse(
        Cookies.get('addToCart') || '[]'
      )
      if (recoverAddToCartPending.length > 0) {
        handleMutate(recoverAddToCartPending)
      }
    }

    const validaReload = (event) => {
      const historyTraversal =
        event.persisted ||
        (typeof window.performance != 'undefined' &&
          performance?.getEntriesByType('navigation')?.[0]?.['type'] ===
            'back_forward')
      if (historyTraversal) {
        window.location.reload()
      }
    }

    checkAddToCartPending()

    window.addEventListener('pageshow', validaReload)
    return () => {
      window.removeEventListener('pageshow', validaReload)
    }
  }, [
    session?.isLoggedIn,
    queryClient.isFetching('orderForm'),
    queryClient.isMutating({ mutationKey: 'mutateOrderForm' })
  ])

  useEffect(() => {
    const handleLoginIfNeeded = () => {
      const currentUrl = new URL(window.location.href)
      const onLoginPage = currentUrl.searchParams.has('login')
      const isProtectedPage = ['/HelpCenter', '/HelpCenter/Others'].includes(
        router.pathname
      )

      if (isProtectedPage && onLoginPage) {
        currentUrl.searchParams.delete('login')
        window.location.href = currentUrl.toString()
      } else {
        const shouldRedirectToLogin =
          !session?.isLoggedIn && !isProtectedPage && !onLoginPage
        const shouldCleanLoginParam = session?.isLoggedIn && onLoginPage
        if (shouldRedirectToLogin && !onLoginPage) {
          currentUrl.searchParams.set('login', 'true')
          router.push(currentUrl.toString())
        } else if (shouldCleanLoginParam) {
          setOrderFormExist(true)
          currentUrl.searchParams.delete('login')
          window.location.href = currentUrl.toString()
        }
      }
    }
    if (!isLoading && !session?.isLoading) {
      handleLoginIfNeeded()
    }
  }, [isLoading, session?.isLoggedIn, session?.isLoading, router.pathname])

  const handleDownloadToastClose = () => {
    setIsDownloadToastOpen(false)
  }

  const handleOnBlurToastButton = () => {
    eventClick({
      event: 'interacciones_referencias_app',
      eventCategory: 'modal_descarga',
      eventAction: 'clic',
      event_label: 'descargar'
    })
  }

  const downloadToastContent = useMemo(() => {
    if (!downloadToastResponse.data) return null
    return new ContentfulResponse(
      downloadToastResponse.data as IContentfulResponse
    )
      .populateEntries()
      .getResponse().items?.[0] as unknown as IAlertMessage
  }, [downloadToastResponse.data])

  const handleOnCloseSmIsWrong = () => {
    setOpenSmIsWrongModal(false)
    setOpenModalSla(false)
    trigger({
      eventType: 'storeLocator',
      data: { show: true, sameAddressOnClose: true }
    })
  }

  // Validate sales channel for SLA NULL flow
  useEffect(() => {
    if (router.pathname !== '/ClaimFlow') {
      localStorage.removeItem('claimData')
    }
    const validateSC = async () => {
      if (!isLoading) {
        // use BE service to validate if user have a different sales channel in another session
        const validateSla = await postValidateSc({
          geoCoordinates: data?.data?.selectedAddresses?.geoCoordinates
        })
        setValidateSlaResponse(validateSla.data)
        setOpenModalSla(validateSla?.status === 409)
      }
    }
    if (
      data?.data?.selectedAddresses?.geoCoordinates?.length > 0 &&
      !paramsApp
    ) {
      validateSC()
    }
    return () => {
      setOpenModalSla(false)
    }
  }, [data?.data?.selectedAddresses, isLoading, router.pathname])

  useEffect(() => {
    if (
      data?.data?.selectedAddresses &&
      !isLoading &&
      !saveCourierNameOneLoad
    ) {
      updateCurierName({
        selectedAddresses: data?.data?.selectedAddresses,
        salesChannel: data?.data?.salesChannel
      })
      saveCourierNameOneLoad = true
    }
  }, [data])

  return (
    <Container isWrap>
      <AuthWrapper />
      <MembershipWrapper />
      {/* Header */}
      {openSmIsWrongModal && !paramsApp && (
        <SomethingIsWrongModal
          errorCode={errorCodeResponse}
          isMobile={isMobile}
          isOpen={openSmIsWrongModal}
          onClose={handleOnCloseSmIsWrong}
          somethingIsWrongImage={somethingIsWrongModalImage}
        />
      )}
      <ValidateSlaWrapper
        openModalSla={openModalSla}
        orderFormData={data?.data}
        paramsApp={paramsApp}
        setOpenModalSla={setOpenModalSla}
        validateSlaResponse={validateSlaResponse?.data}
      />
      {!paramsApp && (
        <>
          {/* Toast */}
          {isDownloadToastOpen && downloadToastContent && (
            <SmallScreen>
              <Toast
                button={downloadToastContent.button}
                fixedPosition={downloadToastContent.fixedPosition}
                hideOnScroll={downloadToastContent.hideOnScroll}
                iconUrl={downloadToastContent.icon?.fields?.file?.url}
                isFixed={downloadToastContent.isFixed}
                message={downloadToastContent.message}
                onBlurButton={handleOnBlurToastButton}
                onClose={handleDownloadToastClose}
                showClose={downloadToastContent.showClose}
                title={downloadToastContent.title}
              />
            </SmallScreen>
          )}

          {showHeader && <Header />}
        </>
      )}

      {children}

      <BigScreen>
        <>
          <Spacer.Horizontal
            backgroundColor={getGlobalStyle('--color-background-white')}
            size={32}
          />
        </>
      </BigScreen>

      {orderFormExist && (
        <>
          <StoreLocatorComponent />
          <DeliverySelectorTypeComponent />
        </>
      )}
      {failedData && (
        <NoStockModal
          data={failedData}
          onClick={() => {
            handleCloseNoStockModal(failedData, setFailedData)
          }}
          orderFormData={data}
        />
      )}
    </Container>
  )
}
