import { useState } from 'react'
import {
  Container,
  MobileModalWrapper,
  Picture
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { useEvents } from '@smu-chile/pkg-unimarc-hooks'
import { variableBreakdownCfBenefits } from '../helper/variableBreakdownCfBenefits'

type HeaderClubBenefitsModalProps = {
  level: string
}

export const HeaderClubBenefitsModal = ({
  level
}: HeaderClubBenefitsModalProps) => {
  const [show, setShow] = useState(false)

  const variables = variableBreakdownCfBenefits()

  const bannerUrl = variables.banners?.find((banner) => {
    return banner.title.includes(level)
  })?.pictureSrc

  const bannerString = bannerUrl ? `https:${bannerUrl}` : ''

  const handleClose = () => {
    setShow(false)
  }

  const handleOpen = () => {
    setShow(true)
  }

  useEvents({
    eventType: 'closeClubBenefitsModal',
    callBack: () => {
      handleClose()
    }
  })

  useEvents({
    eventType: 'openClubBenefitsModal',
    callBack: () => {
      handleOpen()
    }
  })

  return (
    <MobileModalWrapper
      blockId={getBemId('header', 'benefits-modal')}
      body={
        <>
          <Container
            alignItems='center'
            customHeight='100%'
            justifyContent='center'
          >
            <Picture
              height='auto'
              objectFit='contain'
              src={bannerString}
              width='100%'
            />
          </Container>
        </>
      }
      bodyContainerProps={{
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '100%',
        minHeight: 'fit-content',
        overflow: 'hidden'
      }}
      dragProps={{
        isDraggable: false
      }}
      draggButton={false}
      hasFooter={false}
      hasShadow={false}
      headerCloseIconProps={{
        name: 'CloseThin',
        sizes: '2xs'
      }}
      headerTitle='Mis beneficios'
      hideHeaderDivider
      iconSize={0}
      isEnableButton={true}
      modalConfigsProps={{
        isAutoHeight: false,
        isOpen: show,
        minHeightFullScreen: 'calc(100vh - 150px)',
        maxHeightFullScreen: '800px',
        toggle: handleClose,
        toggleOutside: handleClose
      }}
      onClose={handleClose}
      styleProps={{
        padding: '0'
      }}
    />
  )
}
