import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import {
  Column,
  Container,
  DesktopModalWrapper,
  MembershipHeader,
  Picture,
  Text
} from '@smu-chile/pkg-unimarc-components'
import { getBemId } from '@smu-chile/pkg-unimarc-components/helpers'
import { Image } from '@smu-chile/pkg-unimarc-components/stories/organisms/AddToListModal/Image'
import {
  currencyFormat,
  Item,
  sleep,
  useSession,
  useUserSavings
} from '@smu-chile/pkg-unimarc-hooks'
import { FIVE_MINUTES_OUTDATED_INFO } from '@smu-chile/pkg-unimarc-hooks/shared/constants'
import NextImage from 'next/future/image'
import { setMembershipButtons } from 'shared/helpers/membershipHeader'
import { IMembershipDataCF } from 'shared/interfaces/IMembership'
import styles from './MembershipHeaderBrowse.module.css'

type MembershipHeaderBrowseProps = {
  actions?: Array<Item>
  color?: string
  fontColor?: string
  isLoading?: boolean
  level?: string
  membershipDataCF?: IMembershipDataCF
  pictureSrc?: string
  userFirstName?: string
}

export const MembershipHeaderBrowse = ({
  actions,
  color,
  fontColor,
  isLoading,
  level,
  membershipDataCF,
  pictureSrc,
  userFirstName
}: MembershipHeaderBrowseProps) => {
  const router = useRouter()
  const { isLoggedIn } = useSession()
  const [isOpen, setIsOpen] = useState(false)
  const [pictureLevel, setPictureLevel] = useState(false)
  const [modalType, setModalType] = useState(null)

  const userSavings = useUserSavings({
    reactQuery: {
      enabled: isLoggedIn,
      staleTime: FIVE_MINUTES_OUTDATED_INFO
    }
  })

  const accountNumber = currencyFormat({
    number: userSavings?.data?.totalSavings ?? 0,
    bool: false
  })

  const bannerUrl = membershipDataCF?.imageLevels?.find((banner) => {
    return banner.title.includes(level)
  })?.file?.url

  const bannerString = bannerUrl ? `https:${bannerUrl}` : ''

  const handleOpenModal = (buttonLabel: string) => {
    setIsOpen(true)
    if (buttonLabel.includes('ahorr')) {
      setModalType('ahorro')
    }
    if (buttonLabel.includes('beneficio')) {
      setModalType('beneficio')
    }
  }

  const handleClickModalButton = () => {
    if (modalType === 'ahorro') {
      if (level === 'Socio Club') {
        handleCloseModal()
      } else {
        router.push(process.env.NEXT_PUBLIC_HOME_ALLIANCES_URL)
      }
    }
  }

  const handleCloseModal = async () => {
    const benefitsModal = document.getElementById('benefits__container')

    if (benefitsModal) {
      benefitsModal.classList.remove(styles.openModal)
      benefitsModal.classList.add(styles.closeModal)
      await sleep(500)
    }
    setPictureLevel(false)
    setIsOpen(false)
  }

  useEffect(() => {
    const handleOpenModalAnimation = async () => {
      if (modalType === 'beneficio' && isOpen) {
        const benefitsModal = document.getElementById('benefits__container')
        if (benefitsModal) {
          setPictureLevel(true)
          await sleep(500)
          benefitsModal.classList.add(styles.openModal)
          benefitsModal.classList.remove(styles.closeModal)
        }
      }
    }
    handleOpenModalAnimation()
  }, [modalType, isOpen])

  return (
    <>
      <MembershipHeader
        buttons={setMembershipButtons(actions, accountNumber, handleOpenModal)}
        fontColor={fontColor}
        isLoading={isLoading}
        mainBackgroundColor={color}
        type='full'
        userIcon={pictureSrc}
        userLevel={level}
        userName={userFirstName}
      />
      {modalType === 'ahorro' && (
        <DesktopModalWrapper
          blockId={getBemId('saving', 'modal')}
          bodyChildrenProps={{
            minHeight: 'none'
          }}
          borderEdge='none'
          footerChildrenProps={{
            default: true,
            buttonDefaultProps: {
              label: level === 'Socio Club' ? 'Entendido' : 'Ver panoramas'
            },
            onClick: handleClickModalButton
          }}
          hasfooter={true}
          headerCloseIcon={{
            default: true,
            name: 'CloseThin',
            sizes: '2xs'
          }}
          headerTitle={null}
          modalConfigsProps={{
            isOpen,
            toggle: handleCloseModal,
            toggleOutside: handleCloseModal,
            minHeight: '500px'
          }}
          styleProps={{
            height: '500px'
          }}
        >
          <Column
            alignItems='center'
            gap='18px'
            margin='-20px 0 0'
          >
            <Text
              fontSize='xl'
              fontWeight='medium'
              id={getBemId('modal', 'title', 'ahorro')}
            >
              ¡Felicidades!
            </Text>
            <Container id={getBemId('modal', 'image', 'ahorro')}>
              <Image
                altPicture='Club Unimarc image'
                srcPicture={membershipDataCF?.imageSaving}
              />
            </Container>
            <Text
              id={getBemId('modal', 'text', 'ahorro')}
              textAlign='center'
            >
              Haz ahorrado {accountNumber} en tus compras realizadas en las
              tiendas de Unimarc, app y web.
            </Text>
          </Column>
        </DesktopModalWrapper>
      )}
      {modalType === 'beneficio' && (
        <>
          <DesktopModalWrapper
            blockId={getBemId('benefits', 'modal')}
            bodyChildrenProps={{
              maxHeight: '100%',
              overflow: 'hidden',
              maxWidth: '85%'
            }}
            borderEdge='bottom'
            hasfooter={false}
            headerCloseIcon={{
              default: true,
              name: 'CloseThin',
              sizes: '2xs'
            }}
            headerTitle={'Mis beneficios'}
            modalConfigsProps={{
              isOpen,
              toggle: handleCloseModal,
              toggleOutside: handleCloseModal,
              customContainerClassName: styles.modalContainer,
              containerId: getBemId('benefits', 'container'),
              marginFullScreen: 'calc(100vh - 590px) 0 0',
              minHeight: '98vh',
              isWindowBlocked: true,
              maxWidth: '27vw'
            }}
          >
            <Container>
              {pictureLevel && (
                <Picture
                  height='auto'
                  key='MyBenefits'
                  nextImage={NextImage}
                  objectFit='contain'
                  src={bannerString}
                  width='100%'
                />
              )}
            </Container>
          </DesktopModalWrapper>
        </>
      )}
    </>
  )
}
